import React from "react";
import "./App.css";

import { Admin, Resource } from "react-admin";
import {
  FirebaseRealTimeSaga,
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions
} from "react-admin-firebase";
import { PostList, PostShow, PostEdit } from "./destek";
import { OptionList, OptionCreate, OptionEdit } from "./options";

var firebaseConfig = {
  apiKey: "AIzaSyAPax0gCiOMfH8BUcczSpI1qN_ob88p2f0",
  authDomain: "arabic-store.firebaseapp.com",
  databaseURL: "https://arabic-store.firebaseio.com",
  projectId: "arabic-store",
  storageBucket: "arabic-store.appspot.com",
  messagingSenderId: "322078727099",
  appId: "1:322078727099:web:5f3845c7ff04d0a129029e"
};

const options: RAFirebaseOptions = {
  logging: true,
  watch: ["destek"]
};

const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

class App extends React.Component {
  render() {
    return (
      <Admin
        customSagas={[firebaseRealtime]}
        dataProvider={dataProvider}
        authProvider={authProvider}
      >
        <Resource
          name="destek"
          list={PostList}
          show={PostShow}
          edit={PostEdit}
        />
        <Resource
          name="options"
          list={OptionList}
          create={OptionCreate}
          edit={OptionEdit}
        />
      </Admin>
    );
  }
}

export default App;
