import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  DisabledInput,
  SimpleForm,
  TextInput,
  required
} from "react-admin";

export const OptionList = (props: any) => (
  <List {...props}>
    <Datagrid>
      <TextField source="name" />
      <EditButton />
    </Datagrid>
  </List>
);

export const OptionEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <DisabledInput source="id" />
      <TextInput validate={required()} source="name" />
    </SimpleForm>
  </Edit>
);

export const OptionCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput validate={required()} source="name" />
    </SimpleForm>
  </Create>
);
