// in src/posts.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
  Datagrid,
  List,
  Show,
  Edit,
  Filter,
  DisabledInput,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  EditButton,
  DeleteButton,
  RichTextField,
  SelectInput,
  ReferenceField,
  ReferenceInput,
  ImageField,
  ImageInput,
  FunctionField
} from "react-admin";

const PostFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="aciklama" alwaysOn />
      <ReferenceInput
        source="status_id"
        reference="options"
        alwaysOn
        sort={{ field: "id", order: "ASC" }}
      >
        <SelectInput source="name" />
      </ReferenceInput>
    </Filter>
  );
};

export const PostList = (props: any) => (
  <List
    {...props}
    filters={<PostFilter />}
    sort={{ field: "created_at", order: "DESC" }}
    filterDefaultValues={{ status_id: "O1eXT8WPTn3DUAJRmZAy" }}
  >
    <Datagrid>
      <TextField source="aciklama" />
      <TextField source="adres" />
      <TextField source="adresExtra" />
      <TextField source="status.name" />
      <RichTextField source="phone" />
      <ReferenceField label="Status" source="status_id" reference="options">
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label="Video"
        render={(record: any) => (
          <a href={"https://www.google.com/maps/place/" + record.kordinatlar}>
            Google Maps
          </a>
        )}
      />
      <ShowButton label="" />
      <EditButton label="" />
      <DeleteButton label="" />
    </Datagrid>
  </List>
);

export const PostShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="aciklama" />
      <RichTextField source="adres" />
      <RichTextField source="adresExtra" />
      <RichTextField source="phone" />

      <ImageField source="images" src="src" title="name" />
    </SimpleShowLayout>
  </Show>
);

export const PostEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="aciklama" />
      <TextInput source="adres" />
      <TextInput source="adresExtra" />
      <ReferenceInput label="Status" source="status_id" reference="options">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ImageInput source="images" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>

      {/* <FileInput source="file" label="File" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput> */}
    </SimpleForm>
  </Edit>
);
